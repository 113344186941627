import getDateDef from '../../../Dispatch/Common/Generator/getDateDef';
import getCheckBoxDef from './getCheckBoxDef';
import getDateTimeDef from './getDateTimeDef';
import getNumberDef from './getNumberDef';

const DATE_FIELDS = [
  'created_date',
  'eta',
  'lfd',
  'rfd',
  'ava_date',
  'st',
  'erd',
  'po_sche_date',
  'po_actual_in_date',
  'po_actual_out_date',
  'dilv_sche_date',
  'dilv_actual_in_date',
  'dilv_actual_out_date',
  'pu_sche_date',
  'pu_actual_in_date',
  'pu_actual_out_date',
  'rtn_sche_date',
  'rtn_actual_in_date',
  'rtn_actual_out_date',
  'empty_load_cfm',
];

const DATE_TIME_FIELDS = ['cutoff'];

const CHECKBOX_FIELDS = [
  'wms',
  'over_height',
  'over_weight',
  'hot',
  'hazmat',
  'doc_pod',
  'doc_bl',
  'doc_do',
  'doc_it',
];

const NUMBER_FIELDS = [
  'ap',
  'ap_drv',
  'ap_ch',
  'ap_pe',
  'ap_de',
  'ap_dr',
  'ar',
  'trucking',
  'ar_fsc',
  'ar_ch',
  'ar_pe',
  'ar_de',
  'ar_dr',
  'wgt',
  'qty',
  'profit',
];

const getDef = item => {
  if (DATE_FIELDS.includes(item)) {
    return getDateDef(item);
  }
  if (DATE_TIME_FIELDS.includes(item)) {
    return getDateTimeDef(item);
  }
  if (CHECKBOX_FIELDS.includes(item)) {
    return getCheckBoxDef(item);
  }
  if (NUMBER_FIELDS.includes(item)) {
    return getNumberDef(item);
  }
  if (item === 'category') {
    return {
      valueGetter: params => {
        if (params.data?.category === 'REGULAR') return 'REG';
        if (params.data?.category === 'SHUTTLE') return 'SHTL';
        return params.data?.category || '';
      },
    };
  }
  return {};
};

export default getDef;
